import * as React from "react"

import { cn } from "@/app/utils/misc"

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
	({ className, ...props }, ref) => {
		return (
			<textarea
				className={cn(
					`flex min-h-[80px] bg-inherit w-full rounded-md border border-input px-3 py-2 text-sm placeholder:text-color-gray-700 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid`,
					className,
				)}
				ref={ref}
				{...props}
			/>
		)
	},
)
Textarea.displayName = `Textarea`

export { Textarea }
